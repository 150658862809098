import React, { useEffect, useState } from "react";
import "../cursor/Cursor.css";

import { motion } from "framer-motion";

const Cursor = ({ children, scaling }) => {
  const [largeCircle, setLargeCircle] = useState({ x: 0, y: 0 });
  const [smallCircle, setSmallCircle] = useState({ x: 0, y: 0 });
  useEffect(() => {
    const mousemove = (event) => {
      setLargeCircle({ x: event.clientX, y: event.clientY });
      setSmallCircle({ x: event.clientX, y: event.clientY });
    };
    window.addEventListener("mousemove", mousemove);

    return () => {
      window.removeEventListener("mousemove", mousemove);
    };
  }, []);
  return (
    <div>
      <motion.div
        animate={{
          x: largeCircle.x - 32,
          y: largeCircle.y - 32,
          transition: { type: "spring", mass: 3 },
        }}
        className="large_circle"
        style={{ scale: scaling ? 0.5 : 1 }}
      ></motion.div>
      <motion.div
        animate={{
          x: smallCircle.x - 18,
          y: smallCircle.y - 18,
          transition: { type: "spring", mass: 2 },
        }}
        className="small_circle"
        style={{ scale: scaling ? 0.5 : 1 }}
      ></motion.div>
      {children}
    </div>
  );
};
export default Cursor;
